/* Ion.RangeSlider, Simple Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/

/* =====================================================================================================================
// Skin details */

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
    background: url(../lib/ion.rangeSlider/img/sprite-skin-simple.png) repeat-x;
}

.irs {
    height: 40px;
}

.irs-with-grid {
    height: 60px;
}

.irs-line {
    height: 6px;
    top: 25px;
}

.irs-line-left {
    height: 6px;
    background-position: 0 -30px;
}

.irs-line-mid {
    height: 6px;
    background-position: 0 0;
}

.irs-line-right {
    height: 6px;
    background-position: 100% -30px;
}

.irs-bar {
    height: 6px;
    top: 25px;
    background-position: 0 -60px;
}

.irs-bar-edge {
    top: 25px;
    height: 6px;
    width: 7px;
    background-position: 0 -90px;
}

.irs-shadow {
    height: 1px;
    top: 34px;
    background: #000;
    opacity: 0.75;
}

.lt-ie9 .irs-shadow {
    filter: alpha(opacity = 75);
}

.irs-slider {
    width: 8px;
    height: 15px;
    top: 21px;
    background-position: 0 -120px;
    background-color: $primary-1;

    &.state_hover,
    &:hover {
        background-position: 0 -150px;
    }
}

.irs-min,
.irs-max {
    color: #6c81a6;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: #e3e1eb;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.lt-ie9 {
    .irs-min,
    .irs-max {
        background: #3654b0;
    }
}

.irs-from,
.irs-to,
.irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: #e3e1eb;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.lt-ie9 {
    .irs-from,
    .irs-to,
    .irs-single {
        background: #d8dff3;
    }
}

.irs-grid-pol {
    background: #777;
}

.irs-grid-text {
    color: #aba3a3;
}

